import { State } from "@/store/state";
import { Request } from "@/network/request";
import i18n from "@/i18n";
import toast from "@/plugins/toast";
// import cookies from 'vue-cookies'
export default class subject {
  endPoint = "subject";
  tableData = {
    data: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },

      created_at: {
        name: "created_at",
        keys: ["created_at"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };
  getState = new State();
  postState = new State();
  editState = new State();
  showState = new State();
  createState = new State();
  deleteState = new State();

  get(paramas) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}`,
      params: paramas,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.Subjects;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async show(id) {
    let request = new Request({
      method: "get",
      endpoint: `/${this.endPoint}/${id}`,
    });

    await this.showState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.showData = value.data;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async post(payload) {
    let form = new FormData();
    for (let key in payload) {
      form.append(`${key}`, payload[key]);
    }
    let request = new Request({
      method: "post",
      endpoint: `/${this.endPoint}`,
      data: form,
    });

    await this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("create success"),
            { timeout: 4000, rtl: true },
            "sucess"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async edit(payload, id) {
    let form = new FormData();
    for (let key in payload) {
      form.append(`${key}`, payload[key]);
    }
    form.append("_method", "put");
    let request = new Request({
      method: "post",
      endpoint: `/${this.endPoint}/${id}`,
      data: form,
    });

    await this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {},
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  async deleteItem(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/${this.endPoint}/${id}`,
    });

    await this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          toast(
            i18n.t("deleted success"),
            { timeout: 4000, rtl: true },
            "warning"
          );
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Subject = new subject();
